<template>
  <div>
    <b-alert v-model="isDraftAvailable" v-height-fade.appear variant="warning">
      <div class="alert-body">
        <b-row>
          <b-col class="align-self-center">
            Do you want to restore the draft data?
          </b-col>
          <b-col class="align-self-center text-right">
            <b-button @click="removeDraft" variant="outline-danger" class="mr-2"
              >Ignore</b-button
            >
            <b-button @click="restoreDraft" variant="primary">Restore</b-button>
          </b-col>
        </b-row>
      </div>
    </b-alert>
        <b-card title="Media Page">
          <b-row>
            <b-col cols="12">
              <b-form-group label="Page Title">
                <b-form-input
                  v-model="page.title"
                  placeholder="Page Title"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
        <b-card title="Banners">
          <b-form
            class="repeater-form"
            @submit.prevent="repeatBanners()"
          >
           
          <b-col cols="7">
            <b-form-group label="Gallery" label-for="gallery">
              <v-select
                multiple
                label="title"
                v-model="page.banner"
                :reduce="
                  (media) => {
                    return { media: media._id };
                  }
                "
                placeholder="Select Gallery"
                :options="gallery"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <hr />
          </b-col>

          
          </b-form>
        </b-card>
        <b-card title="Gallery">
        <b-form>   
          <b-col cols="7">
            <b-form-group label="Gallery" label-for="gallery">
              <v-select
                multiple
                label="title"
                v-model="page.gallery"
                :reduce="
                  (media) => {
                    return { media: media._id };
                  }
                "
                placeholder="Select Gallery"
                :options="gallery"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <hr />
          </b-col>
          </b-form>
        </b-card>
     <b-card>
      <b-row>
        <b-col cols="6">
          <b-button size="lg" @click="saveDraft" block variant="outline-info"
            >Create Draft</b-button
          >
        </b-col>
        <b-col cols="6">
          <b-button size="lg" @click="savePage" block variant="primary"
            >Save Page</b-button
          >
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import Ripple from "vue-ripple-directive";
import {
  BCard,
  BRow,
  BCol,
  BForm,
  BFormInput,
  BFormGroup,
  BTabs,
  BTab,
  BButton,
  BFormTextarea,
  BImg,
  BAlert,
} from "bootstrap-vue";
import { heightTransition } from "@core/mixins/ui/transition";
import { heightFade } from "@core/directives/animations";
import { getUserToken } from "@/auth/utils";
import vSelect from "vue-select";
import ComponentMediaSelector from "@/views/Gallery/ComponentMediaSelector";
import axios from "axios";

export default {
  directives: {
    "height-fade": heightFade,
    Ripple,
  },
  mixins: [heightTransition],
  components: {
    BCard,
    BRow,
    BCol,
    BForm,
    BFormInput,
    BFormGroup,
    vSelect,
    BTabs,
    BTab,
    BButton,
    BFormTextarea,
    ComponentMediaSelector,
    BImg,
    BAlert,
  },
  data() {
    return {
      isDraftAvailable: false,
      gallery: [],
      page: {
        title: "",
        banner: [],
        gallery: []
      },
    };
  },
  methods: {
    makeToast(variant, title, content) {
      this.$bvToast.toast(content, {
        title: title,
        variant,
        solid: true,
      });
    },
    getGalleryItems() {
       axios
        .get(`${process.env.VUE_APP_SERVER_URL}/gallery/all`, {
          headers: {
            Authorization: `Bearer ${getUserToken()}`,
          },
        })
        .then((response) => {
          console.log('gallery res', response.data)
          this.gallery = response.data

        })
        .catch((error) => {
          console.log(error.response);
          this.makeToast("danger", "Error", "Couldn't load Tender Data");
        });
    },
    removeTenderItem(lang = undefined, index) {
      if (lang) {
        this.page.translation[lang].tenderList.splice(index, 1);
      } else {
        this.page.tenderList.splice(index, 1);
      }
    },
    repeatBanners() {
        this.page.banner.push({
          media: ""
        });
    },
    saveDraft() {
      localStorage.setItem("media-page-data", JSON.stringify(this.page));
      this.makeToast("success", "Success", `Draft Saved!`);
    },
    restoreDraft() {
      const page = localStorage.getItem("media-page-data");
      this.page = JSON.parse(page);
      console.log(this.page)
    },
    removeDraft() {
      localStorage.removeItem("media-page-data");
      this.isDraftAvailable = false;
    },
    getPage() {
      axios
        .get(`${process.env.VUE_APP_SERVER_URL}/pages/media/get`)
        .then((response) => {
            this.page = response.data
            const bannerList = response.data.banner
            ? response.data.banner.map((bannerList) => {
                return {
                  media: bannerList.media._id,
                };
              })
            : [];
        this.page.banner = bannerList;

        const galleryList = response.data.banner
            ? response.data.gallery.map((gallery) => {
                return {
                  media: gallery.media._id,
                };
              })
            : [];
        this.page.gallery = galleryList;
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    savePage() {
      console.log(this.page);
      axios
        .post(`${process.env.VUE_APP_SERVER_URL}/pages/media/save`, this.page, {
          headers: {
            Authorization: `Bearer ${getUserToken()}`,
          },
        })
       .then((response) => {
              this.makeToast(
                "success",
                "Success",
                `${this.page.title} has been added successfully!`
              );
            })
            .catch((error) => {
              this.makeToast(
                "danger",
                "Error",
                "Error while adding the tender!"
              );
              console.log(error.response);
            });
    },
  },
  mounted() {
     this.getGalleryItems();
    if (localStorage.getItem("media-page-data")) {
      this.isDraftAvailable = true;
    }
    this.getPage();
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>